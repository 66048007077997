.HeroSection {
    position: relative;
    width: 100%;
    height: 75vh;
    overflow: hidden;
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    overflow-x: hidden;
    object-position: top;
    background-position: top;
    background-blend-mode: darken;
    position: relative;
    background-color: rgb(0 0 0 / 35%);
    
  }
  
  .HeroSection::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Place it behind the content */
    background-size: cover;
    background-position: center;
    object-position: top;
    object-fit: cover;
  }
  
  .heroBannerContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: rgb(0 0 0 / 49%); /* Add a background overlay */
    padding: 20px;
    border-radius: 10px;
    z-index: 1; /* Place it above the background image */
  }
  
  .title {
    font-size: 2rem;
    color: #fff;
    font-weight: 700;
  }
  
  .subTitle {
    font-size: 1.2rem;
    color: #fff;
    margin-top: 20px;
  }

  .searchInput{
    margin-top: 20px;
  }
  
  .searchInput input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
  }

/* Optional CSS for styling the input container */
.input-container {
  position: relative;
}

/* Style for the clear icon */
.clearIcon {
  position: absolute;
  top: 44%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888;
  font-size: 24px;
}

/* Optional style for the icon when hovered */
.clearIcon:hover {
  color: #f00; /* Change the color on hover if desired */
}
