.logo-img{
    width: 20%;
}

.navbar-nav{
    margin-left: -20%;
    gap: 10px;
}

.navbar-nav .nav-link {
  color: #fff !important ;
}


.navbar-nav .nav-link {
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  padding: 4px 0;
}

.navbar-nav .nav-link::before {
  content: "";
  width: 100%;
  height: 4px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  transform: scale3d(0, 1, 1);
  transform-origin: 0 50%;
  transition: 0.5s transform ease;
}

.navbar-nav .nav-link:hover::before {
  transform: scale3d(1, 1, 1);
}



.search-box{
    margin-left: 20%;
}

.nav-right{
    list-style: none;
    
}

.nav-right a{
    color: #000;
}

.left-side{
    width: 100%;
}


.up-next-image {
  max-width: 100%; /* Adjust this value as needed */
  
}

.banner-image{
width: 100%;
}


.right-side{
display: grid;
grid-template-columns: 33% 33% 33%;
gap: 20px;

}

.title_text{
    font-size: 18px;
    text-align: center;
}

.cards{
    background: #fff;
    border-radius: 0.6em;
    cursor: pointer;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    transition: all ease 200ms;
}

.cards:hover{
    transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}


.slide-image{
    width: 100%;
}

.slider{
    padding: 40px 0;
}


.carousel-slide {
    padding: 20px; /* Add spacing between images */
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add a box shadow */
    border-radius: 10px;
    background-color: white;
    margin: 0 10px; /* Add horizontal margin to separate slides */
}

.slide-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.title-text {
    margin-top: 10px;
    font-size: 22px;
    text-align: center;
    color: #000;
    text-decoration: none !important;
}

.popular-image{
    width: 100%;
    height: 80%;
    object-fit: cover;
    object-position: top;
}

/* Add this to your style.css file or create a new CSS file and link it in your HTML */

/* Style for the table */
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #333;
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  /*  cell style */
  .table th {
    font-weight: bold;
    border-bottom: 2px solid #dee2e6;
    text-align: left;
  }
  
  /* Table rows alternating background color */
  .table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Table rows on hover */
  .table tbody tr:hover {
    background-color: #e0e0e0;
  }
  
  /* Table cell padding and text alignment */
  .table td, .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  
  /* Table cell content alignment */
  .table td {
    text-align: left;
  }
  
  /* Style for the poster image in the table */
  .popular-tables {
    max-width: 200px;
    height: auto;
    border-radius: 4px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Add some space between the cells for better readability */
  .popular-table td {
    padding: 10px;
  }


  @media (max-width: 768px) {
    .table {
      font-size: 14px; /* Decrease font size for better mobile readability */
    }
  
    .popular-tables {
        max-width: 100%; /* Reduce image width for mobile devices */
    }
  }



/* movie details */



/* movie-details.css */



/* Movie Details Container */
.movie-details-container {
  position: relative;
  background-color: #f0f0f0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: background-color 0.3s;
}

.movie-details-container::before {
  content: "";
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.7;
  transition: opacity 0.3s;
}

/* Movie Poster Box */
.movie-poster-container {
  padding: 30px 30px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.movie-poster-container:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.movie-poster {
  max-width: 100%;
  height: 468px; /* Adjust the maximum height as needed */
  display: block;
  object-fit: cover;
  object-position: top;
}

/* Movie Details */
.movie-details {
  padding: 30px 30px;
  padding: 20px;
  position: relative;
  z-index: 1;
}

.movie-title {
  font-size: 32px;
  font-weight: 600;
  margin-top: 0;
}

.movie-tagline {
  font-size: 25px;
  margin-top: 10px;
  font-weight: 400;
}

.movie-overview {
  margin-top: 20px;
  font-size: 18px;
  line-height: 1.6;
}

.tags {
  margin-top: 10px;
}

.tag {
  display: inline-block;
  margin-right: 10px;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.movie-release {
  margin-top: 10px;
  font-size: 16px;
}

.rating { 
  margin-top: 20px;
  font-size: 18px;
  display: inline-block;
}


.all-wrap{
  padding: 25px 0;
  color: #fff;
  background-size: cover; /* Make the background image cover the container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  overflow-x: hidden;
  object-position: top;
  background-position: top;
  background-blend-mode: darken;
  position: relative;
}
.all-wrap::before {
  content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Adjust opacity as needed */
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.6); /* Add a dark shadow */
    z-index: -1;/* Place it behind the content */
}




/* Styles for the modal */
.trailer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.trailer-modal {
  background-color: #fff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  width: 90%; /* Adjust the width as needed */
  max-width: 1000px; /* Set a maximum width */
  max-height: 90vh; /* Adjust the height as needed */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative; /* Add this */
}

.trailer-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 24px;
  color: #999;
  transition: color 0.3s ease;
}

.trailer-modal-close:hover {
  color: #555;
}

.trailer-modal iframe {
  width: 100%;
  height: 600px; /* Adjust the height as needed */
  border: none;
}

/* Add more styles as needed */


/* poster */


.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
}

.card {
  position: relative;
  width: 325px;
  height: 450px;
  background: #000;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.poster {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.poster::before {
  content: '';
  position: absolute;
  bottom: -45%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: 0.3s;
}

.card:hover .poster::before {
  bottom: 0;
}

.poster img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}

.card:hover .poster img {
  transform: scale(1.1);
}

.details {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  padding: 1.5em;
  background: #000a;
  backdrop-filter: blur(16px) saturate(120%);
  transition: 0.3s;
  color: #fff;
  z-index: 2;
}

.card:hover .details {
  bottom: 0;
}

.details h1,
.details h2 {
  font-weight: 700;
}

.details h1 {
  font-size: 1.5em;
  margin-bottom: 5px;
}

.details h2 {
  font-weight: 400;
  font-size: 1em;
  margin-bottom: 10px;
  opacity: 0.6;
}

.details .rating {
  position: relative;
  margin-bottom: 15px;
  display: flex;
  gap: 0.25em;
}

.details .rating i {
  color: #e3c414;
}

.details .rating span {
  margin-left: 0.25em;
}

.details .tags {
  display: flex;
  gap: 0.375em;
  margin-bottom: 0.875em;
  font-size: 0.85em;
}

.details .tags span {
  padding: 0.35rem 0.65rem;
  color: #fff;
  border: 1.5px solid rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  border-radius: 50px;
}

.details .desc {
  color: #fff;
  opacity: 0.8;
  line-height: 1.5;
  margin-bottom: 1em;
}

.details .cast h3 {
  margin-bottom: 0.5em;
}

.details .cast ul {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  width: 100%;
}

.details .cast ul li {
  list-style: none;
  width: 30px; /* Adjust the width to your preference */
  height: 30px; /* Adjust the height to your preference */
  border-radius: 50%;
  overflow: hidden;
  border: 1.5px solid #fff;
}

.details .cast ul li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.card:hover .details .hovered-cast ul {
  position: absolute;
  bottom: 5px;
  left: 40%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.card:hover .details .hovered-cast ul li {
  list-style: none;
  width: 50px; /* Adjust the width to your preference */
  height: 50px; /* Adjust the height to your preference */
  border-radius: 50%;
  overflow: hidden;
  border: 1.5px solid #fff;
}

.card:hover .details .hovered-cast ul li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.button {
  background-color: #d47070; 
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-left: -130px;
}

.image-container {
  max-height: 400px; /* Adjust the maximum height as needed */
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: auto;
}



/*  rating    */
.CircularProgressbar{
  width: 20% !important;
}
.circleRating {
  background-color: var(--black);
  border-radius: 50%;
  padding: 2px;
  .CircularProgressbar-text {
      font-size: 34px;
      font-weight: 700;
      fill: var(--black);
  }
  .CircularProgressbar-trail {
      stroke: transparent;
  }
}

.smiler{
  padding: 40px 0;
  text-align: center;
}

.smiler h3{
  margin-bottom: 30px;
  font-weight: 600;
}

.recomended{
  text-align: center;
}
.recomended h3{
  margin-bottom: 32px;
  font-weight: 600;
}






.tags {
  display: flex;
  gap: 20px;
  margin-bottom: 0.875em;
  font-size: 0.85em;
  align-items: baseline;
}

.tags span {
  padding: 0.35rem 0.65rem;
  border: 1.5px solid rgb(21 20 20 / 89%);
  border-radius: 4px;
  border-radius: 50px;
}

.releseDate{
  display: flex;
  gap: 20px;
  margin-bottom: 0.875em;
  font-size: 0.85em;
  align-items: baseline;
}





/* Footer */


.footer {
  background-color: #333;
  padding: 50px 0;
  color: white;
  position: relative;
}

.footer .contentWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer .menuItems {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .footer .menuItems {
    margin-bottom: 30px;
    gap: 30px;
  }
}

.footer .menuItems .menuItem {
  transition: all ease 0.3s;
  cursor: pointer;
  font-size: 12px;
}

@media (min-width: 768px) {
  .footer .menuItems .menuItem {
    font-size: 16px;
  }
}

.footer .menuItems .menuItem:hover {
  color: #ff69b4;
}

.footer .infoText {
  font-size: 12px;
  line-height: 20px;
  opacity: 0.5;
  text-align: center; /* Center-align the text */
  max-width: 800px;
  margin: 0 auto 20px; /* Center the element horizontally and add margin at the bottom */
}

@media (min-width: 768px) {
  .footer .infoText {
    font-size: 14px;
    margin-bottom: 30px;
  }
}

.footer .socialIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.footer .socialIcons .icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all ease 0.3s;
}

.footer .socialIcons .icon:hover {
  box-shadow: 0 0 10px #ff69b4;
  color: #ff69b4;
}





.hovered-cast {
  display: flex;
  align-items: center; /* Align content vertically in the center */
  margin-top: 25px;
}

.hovered-cast h3 {
  margin-right: 10px; /* Add some spacing between "Cast" and images */
}

.hovered-cast ul {
  list-style: none;
  display: flex;
  gap: 10px;
}

.hovered-cast ul li {
  width: 75px; /* Adjust the width to your preference */
  height: 75px; /* Adjust the height to your preference */
  border-radius: 50%;
  overflow: hidden;
  border: 1.5px solid #fff;
}

.cast-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}




.md-rating{
  margin-top: 20px;
  font-size: 18px;
  display: flex;
  align-items: baseline;
}

.md-rating span{
  display: flex;
  align-items: center;
  gap: 20px;
}

.runtime{
  display: flex;
  gap: 20px;
}


.details-container{
  display: flex;
 gap: 50px; /* Align items with space in between */
  align-items: center; /* Vertically center items */
  margin-bottom: 10px; /* Add some margin between this container and the next one */
}


.tending{
  text-align: center;
  margin: 30px 0;
  font-weight: 700;
}


.releseDate.slide-date{
  margin-left: 100px;
}

.sliding{
  padding: 40px 0;
  text-align: center;
}

.sliders{
margin-bottom: 30px;
font-weight: 700;
}



.centered-table tr {
  text-align: center;
}

.popular-table{
  padding: 40px 0;
}

